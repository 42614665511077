// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-axles-development-jsx": () => import("./../../../src/pages/axles-development.jsx" /* webpackChunkName: "component---src-pages-axles-development-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-design-technology-consultancy-jsx": () => import("./../../../src/pages/design-technology-consultancy.jsx" /* webpackChunkName: "component---src-pages-design-technology-consultancy-jsx" */),
  "component---src-pages-driveline-parts-jsx": () => import("./../../../src/pages/driveline-parts.jsx" /* webpackChunkName: "component---src-pages-driveline-parts-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industrial-equipment-jsx": () => import("./../../../src/pages/industrial-equipment.jsx" /* webpackChunkName: "component---src-pages-industrial-equipment-jsx" */),
  "component---src-pages-security-systems-jsx": () => import("./../../../src/pages/security-systems.jsx" /* webpackChunkName: "component---src-pages-security-systems-jsx" */),
  "component---src-pages-suspension-jsx": () => import("./../../../src/pages/suspension.jsx" /* webpackChunkName: "component---src-pages-suspension-jsx" */)
}

